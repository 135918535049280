<template lang="pug">
div.container
  div(v-if="!organizers")
    loading-spinner(message="Loading organizers...")
  div(v-if="organizers")
    div.row.mb-4
      h1 Create New Event

    div.row
      div.col-md-4.d-none.d-md-block
        b-list-group.mb-3
          b-list-group-item(active) Event Info
          b-list-group-item.d-flex.justify-content-between.align-items-center Name <span>{{newEvent.name}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Date <span>{{newEvent.date | moment("MMMM YYYY")}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Organizer <span>{{newEvent.organizer}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Venue <span>{{newEvent.location}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Sponsor <span>{{newEvent.sponsor}}</span>
      div.col-md-8
        b-form
          b-form-row
            b-form-group.col-sm(label="Event Name *" label-for="name")
              b-input(type="text" name="name" v-model="newEvent.name" required)
            b-form-group.col-sm(label="Date *" label-for="date")
              datepicker(name="date" v-model="newEvent.date" required :bootstrap-styling="true")
            b-form-group.col-sm(label="Organizer *" laber-for="organizer")
              b-select(name="organizer" v-model="newEvent.organizer" :options="organizers" required)
          b-form-row
            b-form-group.col-sm(label="Venue *" label-for="venue")
              b-input(type="text" name="venue" v-model="newEvent.location" required)
            b-form-group.col-sm(label="Sponsor" label-for="sponsor")
              b-input(type="text" name="sponsor" v-model="newEvent.sponsor")
          b-form-row
            b-form-group.col-sm(label="Notes" label-for="notes")
              b-form-textarea(name="notes" v-model="newEvent.notes" :rows="3" :max-rows="6" placeholder="Things that may affect turnout? Weather? Competing events?")
          b-form-row.justify-content-center.m-4
            b-button(variant="success" @click="createEvent(newEvent)" :disabled="!isFormValid") Create Event
</template>

<script>
import Datepicker from 'vuejs-datepicker'

export default {
  components: {
    Datepicker
  },
  data: function () {
    return {
      newEvent: {
        name: '',
        date: new Date(),
        organizer: '',
        notes: '',
        location: '',
        sponsor: '',
        visitorCountOverride: 0,
        visitorCountOffset: 0
      },
      organizers: null
    }
  },
  computed: {
    isFormValid: function () {
      let result = true
      if (this.newEvent.name === '' || this.newEvent.organizer === '' || this.newEvent.location === '') result = false
      return result
    }
  },
  methods: {
    createEvent: function (newEvent) {
      this.axios.post('/api/events/new', newEvent)
        .then(res => {
          this.$notify({
            group: 'success',
            type: 'success',
            title: 'Event created',
            text: res.data.name + ' is now a thing!'
          })

          this.$router.push({ path: '/events/manage' })
        })
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Creating event failed',
            text: err.response.data,
            duration: -1
          })
        })
    }
  },
  mounted: function () {
    this.axios.get('/api/events/organizers')
      .then(res => {
        this.organizers = [{
          value: null, text: 'Select an organizer'
        }]
        for (let o of res.data) {
          this.organizers.push({
            value: o.name, text: o.name
          })
        }
      })
      .catch(err => {
        this.$notify({
          group: 'error',
          type: 'error',
          title: 'Fetching organizers failed',
          text: err.response.data,
          duration: -1
        })
      })
  }
}
</script>
